<template>
  <div class="search-container">
    <h1>Поиск товаров</h1>

    <div class="search-input">
      <el-input
        v-model="searchQuery"
        placeholder="Введите название, артикул или код 1С"
        clearable
        prefix-icon="el-icon-search"
        @input="handleSearch"
      />
    </div>

    <div
      v-if="totalHits"
      class="total-hits"
    >
      Найдено товаров: {{ totalHits }}
    </div>

    <el-table
      v-loading="loading"
      :data="searchResults"
      style="width: 100%"
      border
    >
      <el-table-column
        prop="name"
        label="Наименование"
      />
      <el-table-column
        prop="brand"
        label="Бренд"
      />
      <el-table-column
        prop="article"
        label="Артикул"
      />
      <el-table-column
        prop="externalId"
        label="Код 1С"
      />
    </el-table>
  </div>
</template>

<script>
import axiosInstance from 'api/axiosInstance'

export default {
  name: 'Search',
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      loading: false,
      totalHits: 0,
      searchTimeout: null
    }
  },
  methods: {
    handleSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      if (!this.searchQuery) {
        this.searchResults = []
        this.totalHits = 0
        return
      }

      this.loading = true
      this.searchTimeout = setTimeout(async () => {
        try {
          const response = await axiosInstance('https://test-online-api.nikol.biz/api/admin/meilisearch', {
            params: { q: this.searchQuery }
          })
          console.log('Search response:', response)
          if (response) {
            this.searchResults = response.hits || []
            this.totalHits = response.estimatedTotalHits || 0
          } else {
            this.searchResults = []
            this.totalHits = 0
          }
        } catch (error) {
          this.$message.error('Произошла ошибка при поиске')
          console.error('Search error:', error)
          this.searchResults = []
          this.totalHits = 0
        } finally {
          this.loading = false
        }
      }, 500)
    }
  }
}
</script>

<style scoped>
.search-container {
  padding: 20px;
}

.search-input {
  margin-bottom: 20px;
  max-width: 500px;
}

.total-hits {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}
</style>
